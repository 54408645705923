@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@layer base {
    html {
        font-family: "Poppins";
    }

  }
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('https://fonts.googleapis.com/css2?family=Poppins&display=swap'); /* IE9 Compat Modes */
}

html {
 background-color: rgb(255, 247, 238);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.1);
  mix-blend-mode: multiply;
  filter: contrast(300%) brightness(1000%) saturate(30%) opacity(60%); 
}

.noise {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
}

nav button {
  transition: border-bottom 0.3s ease-in-out;
}