
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    html {
        @apply font-sans
    }

  }
