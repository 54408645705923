.taglineContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 40vh;
    min-height: 500px;
    overflow: hidden;
  }

  .backgroundImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    object-fit: cover;
  }

  .title {
    font-size: 3rem;
    margin-bottom: 1rem;
    margin-left: 2rem;
  }
  
  .word {
    display: inline-block;
    transition: opacity 0.5s, top 0.5s;
    position: relative;
  }
  
  .description {
    font-size: 1.25rem;
    transition: opacity 0.5s, left 0.5s;
    position: relative;
    margin-left: 2rem;
  }
  